html, body {
  height: 100%;
  background-color: rgb(23 23 23);
}

#root {
  height: 100%;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-region {
  flex: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big-logo {
  display: flex;
  justify-content: center;
}

.big-logo img {
  max-height: 130px;
  transition: all 0.3s ease;
}

.app-wrapper {
  position: relative;
}

.my-nav .nav-logo {
  opacity: 0;
  transition: all 0.3s ease;
}

.header {
  background-color: rgb(38 38 38);
}

.header.pinned .my-nav {
  position: fixed;
  z-index: 1;
  top: 0;
  left:0;
  right: 0;
}

.header.pinned .big-logo img {
  height: 0px;
}

.header.pinned .my-nav .nav-logo {
  opacity: 1;
}

::-webkit-scrollbar {
  width: 15px;
  background: rgb(23 23 23);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: rgb(38 38 38);
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(52, 52, 52) rgb(23 23 23);
}